@import '../../../../scss/theme-bootstrap.scss';

// Styles for Carousel Module
.mpp-custom-override-layout {
  .grid--mpp {
    margin: 0 auto;
    overflow: visible;
    &[data-is-slider='1'] {
      display: flex;
    }
    .slick-slide {
      display: flex;
      height: auto;
      margin-#{$rdirection}: 10px;
      @include breakpoint($bp--large-up) {
        margin-#{$rdirection}: 15px;
      }
      &.slide-wrapper {
        flex-wrap: wrap;
      }
      &.grid--mpp__gutter-sizer {
        display: none;
      }
    }
    &[data-items-per-slide] {
      .product-brief {
        width: 50%;
      }
    }
    &[data-items-per-slide='1'] {
      .product-brief {
        width: 100%;
      }
    }
    &.slick-slider {
      width: auto;
    }
  }
  &.is-slider {
    .filters_container {
      display: none;
    }
  }
}
